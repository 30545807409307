import { Label, convertToLabel, labelCollectionKey } from "@/entities/label";
import firebase from "firebase/app";

export async function fetchRoomLabels(
  schoolRef?: firebase.firestore.DocumentReference
): Promise<Label[]> {
  const snapshot = schoolRef
    ? await schoolRef
        .collection(labelCollectionKey)
        .where("type", "==", "room")
        .get()
    : await firebase
        .firestore()
        .collection(labelCollectionKey)
        .where("type", "==", "room")
        .get();

  if (snapshot.empty) {
    return [];
  }

  const labels = snapshot.docs.map(doc => convertToLabel(doc.data(), doc.ref));
  labels.sort((a, b) => a.data.order - b.data.order);

  return labels;
}

export async function fetchTimerLabels(): Promise<
  firebase.firestore.DocumentReference[]
> {
  const snapshot = await firebase
    .firestore()
    .collection(labelCollectionKey)
    .where("type", "==", "timer")
    .get();

  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => doc.ref);
}
