<template>
  <div
    class="p-3 flex items-center bg-white rounded-md cursor-pointer transition-all duration-300 hover:bg-gray-100"
    @click="click"
  >
    <div class="flex-1 flex flex-col">
      <p class="text-xs font-medium text-gray-500">獲得バッジ</p>
      <p class="w-full text-right mt-4">
        <span class="text-xl font-bold">{{ badgeCount }}</span>
        <span class="pl-1 text-sm font-medium text-gray-500"> 個 </span>
      </p>
    </div>
    <m-icon type="chevron-right" class="ml-6 mr-1" />
  </div>
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["click"],
  props: {
    badgeCount: Number
  }
})
export default class MBadgeSummaryCard extends Vue {
  badgeCount!: number;

  click() {
    this.$emit("click");
  }
}
</script>
