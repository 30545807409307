
import MAHistoryPanel from "@/components/analyze/MAHistoryPanel.vue";
import MLearningSummaryCard from "@/components/MLearningSummaryCard.vue";
import MBadgeSummaryCard from "@/components/MBadgeSummaryCard.vue";
import { LearningSummaryData } from "@/components/MLearningSummaryCard.vue";
import {
  convertToDateFromUnixtime,
  convertToUnixtimeFromDate,
  getDateOneDaysAgoFromUnixtime,
  getUnixtimeSevenDaysAgoFrom
} from "@/utils/date";
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { HasBadge } from "@/entities/badge";
import dayjs from "dayjs";

@Options({
  components: {
    MAHistoryPanel,
    MLearningSummaryCard,
    MBadgeSummaryCard
  },
  props: {
    student: Object,
    hasBadges: Array
  }
})
export default class Home extends Vue {
  student!: Student;
  hasBadges: HasBadge[] = [];

  get point(): LearningSummaryData[] {
    return [
      {
        num: this.student.data.totalDayCount,
        label: "ポイント"
      }
    ];
  }

  get badgeCount(): number {
    return this.hasBadges.reduce((pre, current) => {
      if (current.data.stepIndex >= 0) {
        return pre + current.data.stepIndex + 1;
      } else {
        return pre + 1;
      }
    }, 0);
  }

  get currentRunningDayCount(): LearningSummaryData[] {
    const oneDayAgoDate = getDateOneDaysAgoFromUnixtime(
      Math.floor(Date.now() / 1000)
    );
    if (
      this.student.data.latestLearnings.length === 0 ||
      this.student.data.latestLearnings[0].start <
        convertToUnixtimeFromDate(oneDayAgoDate)
    ) {
      return [
        {
          num: 0,
          label: "日"
        }
      ];
    }

    return [
      {
        num: this.student.data.currentRunningDayCount,
        label: "日"
      }
    ];
  }

  get maxRunningDayCount(): LearningSummaryData[] {
    return [
      {
        num: this.student.data.maxRunningDayCount,
        label: "日"
      }
    ];
  }

  get lastWeekStartTime() {
    const start = dayjs().locale("ja").add(-7, "day").startOf("day");
    return start.unix();
  }
  get lastWeekEndTime() {
    const end = dayjs().locale("ja").add(-1, "day").endOf("day");
    return end.unix();
  }

  get sevenDaysTime(): LearningSummaryData[] {
    const learningTime = this.student.data.latestLearnings
      .filter(
        l =>
          l.start >= this.lastWeekStartTime && l.start <= this.lastWeekEndTime
      )
      .reduce((pre, current) => pre + current.time, 0);
    const hours = Math.floor(learningTime / 3600);
    const minutes = Math.floor((learningTime % 3600) / 60);
    return [
      {
        num: hours,
        label: "時間"
      },
      {
        num: minutes,
        label: "分"
      }
    ];
  }

  get sevenDaysDayCount(): LearningSummaryData[] {
    const learnings = this.student.data.latestLearnings.filter(
      l => l.start >= this.lastWeekStartTime && l.start <= this.lastWeekEndTime
    );
    const dates: string[] = [];
    learnings.forEach(learning => {
      const date = convertToDateFromUnixtime(learning.start);
      if (!dates.includes(date)) {
        dates.push(date);
      }
    });
    return [
      {
        num: dates.length,
        label: "日"
      }
    ];
  }

  moveToBadge() {
    this.$router.push("/badge");
  }
}
