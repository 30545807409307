<template>
  <section class="flex flex-col w-full mb-8">
    <h2 class="text-lg font-bold">学習履歴</h2>
    <div class="text-gray-700 text-xs">
      ※通信が不安定な場合、まれにマイナス表示や「NaN」の表示となる場合があります。その場合は先生に学習データの編集・削除をお願いしてください。
    </div>
    <router-link
      v-for="learning in learnings"
      :key="learning.ref.id"
      :to="pathOf(learning)"
      class="mt-3"
    >
      <article class="flex flex-col p-3 rounded-md bg-white">
        <span class="text-xs font-medium text-gray-500">{{
          leaningTypeOf(learning)
        }}</span>
        <p class="font-medium">{{ timeTextOf(learning) }}</p>
        <div v-if="hasReflectionOf(learning)" class="flex flex-wrap">
          <span
            v-for="subject in subjectsOf(learning)"
            :key="subject"
            class="px-3 py-1 mr-3 mt-2 border border-secondary-500 rounded-full text-secondary-500 text-sm"
          >
            {{ subject }}
          </span>
        </div>
        <div v-else class="flex items-center mt-2 text-yellow-300">
          <m-icon type="warning" size="6" class="mr-2" />
          <span class="text-sm font-medium text-gray-500">
            振り返りが終わっていません
          </span>
        </div>
      </article>
    </router-link>
    <div v-if="loadable" class="flex justify-center py-4">
      <div
        class="px-4 py-2 rounded-md text-gray-700 text-sm underline transition-colors duration-300 cursor-pointer hover:bg-gray-100"
        @click="load"
      >
        <span v-show="!loading">さらに読み込む</span>
        <span v-show="loading">読み込み中...</span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Learning } from "@/entities/learning";
import MIcon from "@/components/MIcon.vue";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import "dayjs/locale/ja"; //曜日を英語表記から日本語表記にするために使用

@Options({
  emits: ["load"],
  props: {
    learnings: Array,
    loadable: Boolean,
    loading: Boolean
  },
  components: {
    MIcon
  }
})
export default class MAHistoryPanel extends Vue {
  learnings: Learning[] = [];
  loadable = false;
  loading = false;

  timeTextOf(learning: Learning): string {
    const timeRecords = [...learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    const endTime = dayjs(
      timeRecords[timeRecords.length - 1].end * 1000
    ).locale("ja");
    return (
      startTime.format("M/D(ddd) HH:mm") + " 〜 " + endTime.format("HH:mm")
    );
  }

  hasReflectionOf(learning: Learning): boolean {
    return !!learning.data.reflection;
  }

  subjectsOf(learning: Learning): string[] {
    return learning.data?.subjects.map(subject => subject.name) ?? [];
  }

  pathOf(learning: Learning): string {
    return `/learning/${learning.ref.id}${
      learning.data.reflection ? "" : "/reflection"
    }`;
  }

  leaningTypeOf(learning: Learning): string {
    return learning.data?.meta?.type === "timer" ? "タイムキーパー" : "学習室";
  }

  load() {
    this.$emit("load");
  }
}
</script>
