<template>
  <section
    v-if="regularlyReservations.length > 0"
    class="flex flex-col w-full mb-8"
  >
    <h2 class="text-lg font-bold">学習予約 (定期)</h2>
    <m-reservation-card
      v-for="reservation in regularlyReservations"
      :key="reservation.ref.id"
      :text="timeTextOfRegularlyReservation(reservation)"
      @delete="deleteRegularlyReservation(reservation.ref.id)"
    />
  </section>
  <section v-if="reservations.length > 0" class="flex flex-col w-full mb-8">
    <h2 class="text-lg font-bold">学習予約</h2>
    <m-reservation-card
      v-for="reservation in reservations"
      :key="reservation.ref.id"
      :text="timeTextOf(reservation)"
      @delete="deleteReservation(reservation.ref.id)"
    />
  </section>
  <article
    v-else-if="regularlyReservations.length === 0"
    class="flex flex-col items-center w-full py-6 px-3 rounded-md bg-white"
  >
    <img
      src="@/assets/blank-reservation.svg"
      alt="学習予約"
      class="w-1/2 mb-4"
    />
    <h4 class="mb-2 text-gray-700 font-medium">次回の学習予約がありません</h4>
    <p class="mb-4 text-sm text-gray-500">
      学習予約をすると予約 10 分前にお知らせが届きます
    </p>
    <MButton @click="moveReservation">予約する</MButton>
  </article>
</template>

<script lang="ts">
import MReservationCard from "@/components/MReservationCard.vue";
import MButton from "@/components/form/MButton.vue";
import { RegularlyReservation } from "@/entities/regularly_reservation";
import { Reservation } from "@/entities/reservation";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MReservationCard,
    MButton
  },
  emits: ["delete", "deleteRegular"],
  props: {
    reservations: Array,
    regularlyReservations: Array
  }
})
export default class MAFuturePanel extends Vue {
  reservations: Reservation[] = [];
  regularlyReservations: RegularlyReservation[] = [];

  timeTextOf(reservation: Reservation): string {
    const startTime = dayjs(
      (reservation.data.notifyTime + reservation.data.beforeMinute * 60) * 1000
    ).locale("ja");
    return startTime.format("M/D HH:mm") + " 〜 ";
  }

  timeTextOfRegularlyReservation(reservation: RegularlyReservation): string {
    const timeText = dayjs(`2021-04-01 ${reservation.data.time}`)
      .add(reservation.data.beforeMinute, "minute")
      .format("HH:mm");
    if (reservation.data.type === "month") {
      return `毎月${reservation.data.monthday}日 ${timeText}`;
    } else if (reservation.data.type === "week") {
      let weekdayText = "";
      if (reservation.data.weekday === 0) {
        weekdayText = "日";
      } else if (reservation.data.weekday === 1) {
        weekdayText = "月";
      } else if (reservation.data.weekday === 2) {
        weekdayText = "火";
      } else if (reservation.data.weekday === 3) {
        weekdayText = "水";
      } else if (reservation.data.weekday === 4) {
        weekdayText = "木";
      } else if (reservation.data.weekday === 5) {
        weekdayText = "金";
      } else if (reservation.data.weekday === 6) {
        weekdayText = "土";
      }
      return `毎週${weekdayText}曜日 ${timeText}`;
    } else {
      return `毎日 ${timeText}`;
    }
  }

  deleteReservation(id: string) {
    this.$emit("delete", id);
  }

  deleteRegularlyReservation(id: string) {
    this.$emit("deleteRegular", id);
  }

  moveReservation() {
    this.$router.push("/room/reservation");
  }
}
</script>
