
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["click"],
  props: {
    badgeCount: Number
  }
})
export default class MBadgeSummaryCard extends Vue {
  badgeCount!: number;

  click() {
    this.$emit("click");
  }
}
