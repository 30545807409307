import dayjs from "dayjs";

export function convertToDateFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("YYYY-MM-DD");
}
export function convertToTimeFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .format("HH:mm");
}

export function convertToUnixtimeFromDate(date: string): number {
  return dayjs(date).locale("ja").unix();
}

// 引数で与えられた unixtime の 28日前の日本時間午前0時の unixtime を取得
export function getUnixtimeFourWeeksAgoFrom(unixtime: number): number {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(-28, "day")
    .unix();
  const date = convertToDateFromUnixtime(time);
  return convertToUnixtimeFromDate(date);
}

// 引数で与えられた unixtime の 7日前の日本時間午前0時の unixtime を取得
export function getUnixtimeSevenDaysAgoFrom(unixtime: number): number {
  const time = dayjs(unixtime * 1000)
    .locale("ja")
    .add(-7, "day")
    .unix();
  const date = convertToDateFromUnixtime(time);
  return convertToUnixtimeFromDate(date);
}

export function getDateOneDaysAgoFromUnixtime(unixtime: number): string {
  return dayjs(unixtime * 1000)
    .locale("ja")
    .add(-1, "day")
    .format("YYYY-MM-DD");
}
