<template>
  <section class="flex flex-col w-full mb-4">
    <h2 class="text-lg font-bold">学習記録</h2>
    <div class="flex flex-col items-center py-3 bg-white mt-3">
      <div class="mb-1 flex justify-center max-w-screen-sm">
        <div
          class="flex items-center border border-primary-500 rounded-md text-sm text-primary-600"
        >
          <div
            class="py-1 px-2 border-r border-primary-500 cursor-pointer transition-colors duration-300"
            :class="{
              'bg-primary-500': !isBarChart,
              'text-white': !isBarChart,
              'font-medium': !isBarChart
            }"
            @click="selectIsBarChart(false)"
          >
            円グラフ
          </div>
          <div
            class="py-1 px-2 cursor-pointer transition-colors duration-300"
            :class="{
              'bg-primary-500': isBarChart,
              'text-white': isBarChart,
              'font-medium': isBarChart
            }"
            @click="selectIsBarChart(true)"
          >
            棒グラフ
          </div>
        </div>
      </div>
      <div class="my-2 flex justify-center max-w-screen-sm">
        <div v-if="labelChoices.length > 0" class="flex items-center text-sm">
          <div
            v-for="labelChoice in labelChoices"
            :key="labelChoice.id"
            class="mx-1"
          >
            <input
              :id="`label-choice-${labelChoice.id}`"
              type="radio"
              name="label-choice"
              :disabled="waitingForNextAnimation"
              :checked="labelChoice.selected"
              @change="selectLabelChoice(labelChoice)"
            />
            <label
              :for="`label-choice-${labelChoice.id}`"
              class="pl-1 text-xs"
              >{{ labelChoice.name }}</label
            >
          </div>
        </div>
      </div>
      <div v-if="chartDatasets.length > 0" class="w-full max-w-screen-sm">
        <m-a-bar-chart
          v-if="isBarChart"
          :labels="chartLabels"
          :datasets="chartDatasets"
        />
        <div v-else class="sm:px-8">
          <m-a-doughnut-chart
            :labels="doughnutLabels"
            :datasets="doughnutDatasets"
          />
        </div>
      </div>
      <div
        v-else
        class="w-full h-60 flex items-center justify-center bg-gray-100 rounded-md"
      >
        <p class="text-xs text-gray-700 px-4">
          対象期間の学習履歴が無いか、読み込み中です。
        </p>
      </div>
    </div>
    <div class="w-full mt-3 flex flex-col items-center">
      <div class="w-full py-2 flex items-center">
        <p class="mr-1 text-xs text-gray-700">開始</p>
        <m-text-field
          name="chartStart"
          type="date"
          placeholder="2021/04/01"
          :value="chartStart"
          entered
          class="flex-1"
          @input="chartStart = $event"
        />
      </div>
      <div class="w-full py-2 flex items-center">
        <p class="mr-1 text-xs text-gray-700">終了</p>
        <m-text-field
          name="chartStart"
          type="date"
          placeholder="2021/04/01"
          :value="chartEnd"
          entered
          class="flex-1"
          @input="chartEnd = $event"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import MTextField from "@/components/form/MTextField.vue";
import MABarChart from "@/components/analyze/MABarChart.vue";
import MADoughnutChart from "@/components/analyze/MADoughnutChart.vue";
import { Student, StudentLearning } from "@/entities/student";
import {
  Learning,
  getStudyingSecondsByRecord,
  subjectNames,
  colorsForSubjects
} from "@/entities/learning";
import { Label, LabelChoice } from "@/entities/label";
import { getLearningsOf } from "@/api/learning";
import { fetchRoomLabels } from "@/api/label";
import {
  convertToDateFromUnixtime,
  convertToUnixtimeFromDate,
  getDateOneDaysAgoFromUnixtime,
  getUnixtimeSevenDaysAgoFrom
} from "@/utils/date";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import { saveErrorLog } from "@/api/error";
import store, { awaitStudentLoaded } from "@/store";

export type LearningSummaryData = {
  num: number;
  label: string;
};

const basicLearningCount = 10;

@Options({
  components: {
    MABarChart,
    MADoughnutChart,
    MTextField
  },
  emits: ["open"],
  props: {
    student: Object
  },
  watch: {
    chartStart: function () {
      const startData = dayjs(this.chartStart).locale("ja");
      const endData = dayjs(this.chartEnd).locale("ja").add(1, "day");
      const dayCount = endData.diff(startData, "day");
      if (dayCount <= 0) {
        this.chartStart = this.chartEnd;
      }
    },
    chartEnd: function () {
      const startData = dayjs(this.chartStart).locale("ja");
      const endData = dayjs(this.chartEnd).locale("ja").add(1, "day");
      const dayCount = endData.diff(startData, "day");
      if (dayCount <= 0) {
        this.chartEnd = this.chartStart;
      }
    }
  }
})
export default class MSHistoryPanel extends Vue {
  student!: Student;
  learnings: Learning[] = [];
  learningLimit = basicLearningCount;
  allLearningsFetched = false;
  chartStart = "";
  chartEnd = "";
  isBarChart = false;
  waitingForNextAnimation = false; // 連続してグラフを切り替えると発生するエラー回避
  labelChoices: LabelChoice[] = [];

  get chartStandards(): number[] {
    if (!this.chartStart || !this.chartEnd) {
      return [];
    }
    const startData = dayjs(this.chartStart).locale("ja").startOf("day");
    const endData = dayjs(this.chartEnd).locale("ja").endOf("day");
    const dayCount = endData.diff(startData, "day");
    if (dayCount <= 0) {
      return [];
    }

    if (dayCount <= 14) {
      // 1日毎
      return Array.from(Array(dayCount).keys()).map(i =>
        startData.add(i, "day").unix()
      );
    } else if (dayCount <= 63) {
      // 1週毎
      return Array.from(Array(Math.ceil(dayCount / 7)).keys()).map(i =>
        startData.add(i * 7, "day").unix()
      );
    } else if (dayCount <= 365) {
      // 1月毎
      const standards: number[] = [];
      let current = startData;
      while (current.unix() < endData.unix()) {
        standards.push(current.unix());
        current = current.add(1, "month");
      }
      return standards;
    } else {
      // 1年毎
      const standards: number[] = [];
      let current = startData;
      while (current.unix() < endData.unix()) {
        standards.push(current.unix());
        current = current.add(1, "year");
      }
      return standards;
    }
  }

  get chartLabels(): string[] {
    if (this.chartStandards.length === 0) {
      return [];
    }

    const startData = dayjs(this.chartStart).locale("ja").startOf("day");
    const endData = dayjs(this.chartEnd).locale("ja").endOf("day");
    const dayCount = endData.diff(startData, "day");
    if (dayCount <= 14) {
      // 日付 (MM/DD)をlabelにする
      return Array.from(Array(dayCount).keys()).map(i =>
        startData.add(i, "day").format("M/D")
      );
    } else if (dayCount <= 63) {
      // 週をlabelにする
      return Array.from(Array(Math.ceil(dayCount / 7)).keys()).map(i =>
        startData.add(i * 7, "day").format("M/D〜")
      );
    } else if (dayCount <= 365) {
      // 月をlabelにする
      const labels: string[] = [];
      let current = startData;
      while (current.unix() < endData.unix()) {
        labels.push(current.format("M/D〜"));
        current = current.add(1, "month");
      }
      return labels;
    } else {
      // 年をlabelにする
      const labels: string[] = [];
      let current = startData;
      while (current.unix() < endData.unix()) {
        labels.push(current.format("YYYY/M/D〜"));
        current = current.add(1, "year");
      }
      return labels;
    }
  }

  get chartDatasets(): {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[] {
    if (this.chartStandards.length === 0) {
      return [];
    }
    const hoursForEachSubject: { [key: string]: number[] } = {};
    this.getFilteredLearnings([...this.learningsForChart]).forEach(learning => {
      const totalRatio = learning.data.subjects.reduce(
        (pre, current) => pre + current.ratio,
        0
      );
      const totalHour = learning.data.totalTime / 3600;
      let dataIndex = 0;
      if (this.chartStandards.length > 1) {
        while (this.chartStandards[dataIndex + 1] < learning.data.timestamp) {
          dataIndex += 1;
        }
      }
      learning.data.subjects.forEach(subject => {
        if (!(subject.name in hoursForEachSubject)) {
          hoursForEachSubject[subject.name] = Array(
            this.chartStandards.length
          ).fill(0);
        }
        hoursForEachSubject[subject.name][dataIndex] +=
          (totalHour / totalRatio) * subject.ratio;
      });
    });
    for (const subjectName in hoursForEachSubject) {
      hoursForEachSubject[subjectName] = hoursForEachSubject[subjectName].map(
        hour => Math.round(hour * 1000) / 1000
      );
    }

    const datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[] = [];
    subjectNames.forEach(key => {
      if (key in hoursForEachSubject) {
        datasets.push({
          label: key,
          data: hoursForEachSubject[key],
          backgroundColor: colorsForSubjects[key].color,
          borderColor: colorsForSubjects[key].border,
          borderWidth: 1
        });
      }
    });
    return datasets;
  }

  get doughnutStandards(): { name: string; time: number }[] {
    const standards: { name: string; time: number }[] = subjectNames.map(
      name => ({
        name,
        time: 0
      })
    );
    this.getFilteredLearnings([...this.learningsForChart]).forEach(learning => {
      const totalRatio = learning.data.subjects.reduce(
        (pre, current) => pre + current.ratio,
        0
      );
      learning.data.subjects.forEach(subject => {
        const index = subjectNames.indexOf(subject.name);
        if (index >= 0) {
          standards[index].time +=
            (learning.data.totalTime / totalRatio) * subject.ratio;
        }
      });
    });
    standards.sort((a, b) => b.time - a.time);
    return standards.filter(item => item.time > 0);
  }

  get doughnutLabels(): string[] {
    return this.doughnutStandards.map(item => item.name);
  }

  get doughnutDatasets(): {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[] {
    return [
      {
        data: this.doughnutStandards.map(item => item.time / 3600),
        backgroundColor: this.doughnutStandards.map(
          item => colorsForSubjects[item.name].color
        ),
        borderColor: this.doughnutStandards.map(
          item => colorsForSubjects[item.name].border
        ),
        borderWidth: 1
      }
    ];
  }

  get learningsForChart(): Learning[] {
    const startTime = dayjs(this.chartStart).locale("ja").startOf("day").unix();
    const endTime = dayjs(this.chartEnd).locale("ja").endOf("day").unix();
    return this.learnings.filter(
      learning =>
        learning.data.timestamp >= startTime &&
        learning.data.timestamp < endTime
    );
  }

  get totalTimeLabel(): string {
    return (
      dayjs(this.chartStart).locale("ja").format("YYYY/M/D") +
      " 〜 " +
      dayjs(this.chartEnd).locale("ja").format("YYYY/M/D") +
      "の学習時間"
    );
  }

  get totalTimeOfChart(): LearningSummaryData[] {
    const totalMinutes =
      this.getFilteredLearnings(this.learningsForChart).reduce(
        (pre, current) =>
          pre + getStudyingSecondsByRecord(current.data.records),
        0
      ) / 60;
    const hour = Math.floor(totalMinutes / 60);
    const minute = Math.floor(totalMinutes % 60);

    return [
      {
        num: hour,
        label: "時間"
      },
      {
        num: minute,
        label: "分"
      }
    ];
  }

  get learningsForCard(): Learning[] {
    return this.learnings.length < this.learningLimit
      ? this.learnings
      : this.learnings.filter((_, i) => i < this.learningLimit);
  }

  get point(): LearningSummaryData[] {
    return [
      {
        num: this.student.data.totalDayCount,
        label: "ポイント"
      }
    ];
  }

  get currentRunningDayCount(): LearningSummaryData[] {
    const oneDayAgoDate = getDateOneDaysAgoFromUnixtime(
      Math.floor(Date.now() / 1000)
    );
    const matchedLatestLearnings = this.getFilteredLatestLearnings(
      this.student.data.latestLearnings
    );
    if (
      matchedLatestLearnings.length === 0 ||
      matchedLatestLearnings[0].start < convertToUnixtimeFromDate(oneDayAgoDate)
    ) {
      return [
        {
          num: 0,
          label: "日"
        }
      ];
    }

    return [
      {
        num: this.student.data.currentRunningDayCount,
        label: "日"
      }
    ];
  }

  get maxRunningDayCount(): LearningSummaryData[] {
    return [
      {
        num: this.student.data.maxRunningDayCount,
        label: "日"
      }
    ];
  }

  get lastWeekStartTime() {
    const start = dayjs().locale("ja").add(-7, "day").startOf("day");
    return start.unix();
  }

  get lastWeekEndTime() {
    const end = dayjs().locale("ja").add(-1, "day").endOf("day");
    return end.unix();
  }

  get sevenDaysTime(): LearningSummaryData[] {
    const learningTime = this.getFilteredLatestLearnings(
      this.student.data.latestLearnings
    )
      .filter(
        l =>
          l.start >= this.lastWeekStartTime && l.start <= this.lastWeekEndTime
      )
      .reduce((pre, current) => pre + current.time, 0);
    const hours = Math.floor(learningTime / 3600);
    const minutes = Math.floor((learningTime % 3600) / 60);
    return [
      {
        num: hours,
        label: "時間"
      },
      {
        num: minutes,
        label: "分"
      }
    ];
  }

  get sevenDaysDayCount(): LearningSummaryData[] {
    const learnings = this.getFilteredLatestLearnings(
      this.student.data.latestLearnings
    ).filter(
      l => l.start >= this.lastWeekStartTime && l.start <= this.lastWeekEndTime
    );
    const dates: string[] = [];
    learnings.forEach(learning => {
      const date = convertToDateFromUnixtime(learning.start);
      if (!dates.includes(date)) {
        dates.push(date);
      }
    });
    return [
      {
        num: dates.length,
        label: "日"
      }
    ];
  }

  selectLabelChoice(labelChoice: LabelChoice) {
    if (
      (this.labelChoices.length === 1 && labelChoice.selected) ||
      this.waitingForNextAnimation
    )
      return;
    this.labelChoices = this.labelChoices.map(l => {
      l.selected = labelChoice.id === l.id;
      return l;
    });
    this.waitingForNextAnimation = true;
    window.setTimeout(() => {
      this.waitingForNextAnimation = false;
    }, 1200);
  }

  selectIsBarChart(isBarChart: boolean) {
    if (
      this.labelChoices.length === 1 ||
      isBarChart === this.isBarChart ||
      this.waitingForNextAnimation
    )
      return;
    this.isBarChart = isBarChart;
    this.waitingForNextAnimation = true;
    window.setTimeout(() => {
      this.waitingForNextAnimation = false;
    }, 850);
  }

  getFilteredLearnings(learnings: Learning[]): Learning[] {
    const selectedLabelChoice = this.labelChoices.find(l => l.selected);
    const mingakuRoomLabelChoice = this.labelChoices.find(l => l.order === 1);
    if (!selectedLabelChoice || !mingakuRoomLabelChoice) return [];

    switch (selectedLabelChoice.id) {
      case "all":
        return [...learnings];
      case "others":
        // タイムキーパーとみんがく学習室以外の room の学習記録が抽出される。
        return learnings.filter(
          learning =>
            learning.data.meta &&
            learning.data.meta.labels.every(
              l => l.id !== mingakuRoomLabelChoice.id
            )
        );
      default:
        return learnings.filter(
          learning =>
            // 選択中の label がついた学習記録を抽出する。
            (learning.data.meta &&
              learning.data.meta.labels.some(
                l => l.id === selectedLabelChoice.id
              )) ||
            // 選択されたのが order: 1 の label （みんがく学習室）の場合、meta フィールドがない label も抽出される。
            (!learning.data.meta && selectedLabelChoice.order === 1)
        );
    }
  }

  getFilteredLatestLearnings(
    latestLearnings: StudentLearning[]
  ): StudentLearning[] {
    const selectedLabelChoice = this.labelChoices.find(l => l.selected);
    const mingakuRoomLabelChoice = this.labelChoices.find(l => l.order === 1);
    if (!selectedLabelChoice || !mingakuRoomLabelChoice) return [];
    switch (selectedLabelChoice.id) {
      case "all":
        return [...latestLearnings];
      case "others":
        return latestLearnings.filter(
          latestLearning =>
            latestLearning.labels &&
            latestLearning.labels.every(l => l.id !== mingakuRoomLabelChoice.id)
        );
      default:
        return latestLearnings.filter(
          latestLearning =>
            (latestLearning.labels &&
              latestLearning.labels.some(
                l => l.id === selectedLabelChoice.id
              )) ||
            (!latestLearning.labels && selectedLabelChoice.order === 1)
        );
    }
  }

  timeTextOf(learning: Learning): string {
    const timeRecords = [...learning.data.records];
    if (timeRecords.length === 0) return "";
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    const endTime = dayjs(
      timeRecords[timeRecords.length - 1].end * 1000
    ).locale("ja");
    return startTime.format("M/D HH:mm") + " 〜 " + endTime.format("HH:mm");
  }

  hasReflectionOf(learning: Learning): boolean {
    return !!learning.data.reflection;
  }

  subjectsOf(learning: Learning): string[] {
    return learning?.data?.subjects.map(subject => subject.name) ?? [];
  }

  pathOf(learning: Learning): string {
    return `history/${learning.ref.id}`;
  }

  open() {
    this.$emit("open");
  }

  async getMoreLearning() {
    this.learningLimit += basicLearningCount;
    if (this.learnings.length <= this.learningLimit) {
      this.allLearningsFetched = true;
    }
  }

  async created() {
    try {
      const student = await awaitStudentLoaded(store);
      this.learnings = (await getLearningsOf(student.ref)) ?? [];
      const roomLabels: Label[] = await fetchRoomLabels();
      const mingakuLabel = roomLabels.find(
        label => label && label.data.type === "room" && label.data.order === 1
      );
      if (!mingakuLabel) return;
      /*
        取得した学習履歴に含まれる label を含む、絞り込みの選択肢を作成する。
        「総計」
        「みんがく学習室」（order: 1 ）と
        「みんがく学習室以外（タイムキーパーはこれに含む）」

        みんがく学習室がない場合は「総計」だけ
      */
      this.labelChoices = [
        { name: "総計", id: "all", selected: true, order: -1 },
        {
          name: `${mingakuLabel.data.name}のみ`,
          id: mingakuLabel.ref.id,
          selected: false,
          order: mingakuLabel.data.order
        },
        {
          name: `${mingakuLabel.data.name}以外`,
          id: "others",
          selected: false,
          order: 65535
        }
      ] as LabelChoice[];
      if (this.learnings.length <= this.learningLimit) {
        this.allLearningsFetched = true;
      }
    } catch (e) {
      alert(`学習履歴の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        this.student,
        e.code,
        e.message,
        "Failed to get learnings"
      );
    }
    const now = dayjs().locale("ja");
    this.chartEnd = now.format("YYYY-MM-DD");
    this.chartStart = now.add(-13, "day").format("YYYY-MM-DD");
  }
}
</script>

<style lang="scss" scoped>
.main-panel {
  height: calc(100% - 3rem);
}
</style>
