<template>
  <article
    class="flex justify-between items-center w-full p-3 mt-4 bg-white rounded-md"
  >
    <p class="font-medium">{{ text }}</p>
    <div class="flex-0 text-red-500" @click="deleteData">
      <m-icon type="trash" />
    </div>
  </article>
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  emits: ["delete"],
  props: {
    text: String
  }
})
export default class MReservationCard extends Vue {
  text = "";

  deleteData() {
    this.$emit("delete");
  }
}
</script>
