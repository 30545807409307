<template>
  <div class="canvas-frame" :class="{ 'opacity-0': !renderCanvasElement }">
    <canvas v-if="renderCanvasElement" id="chart-doughnut" />
  </div>
</template>

<script lang="ts">
import Chart, { ChartItem } from "chart.js/auto";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    labels: Array,
    datasets: Array
  },
  watch: {
    labels: function() {
      this.renderChart();
    }
  }
})
export default class MsDoughnutChart extends Vue {
  labels: string[] = [];
  datasets = [];
  chart: Chart | null = null;
  renderCanvasElement = true;

  renderChart() {
    if (this.labels.length === 0 || this.datasets.length === 0) {
      return;
    }
    /*
      連続して切り替えたとき Chart オブジェクトの destroy() でエラーになり
      以降グラフが描画されなくなることがあった。
      毎回新しい canvas 要素を render して destory() をよばなくてよいようにするため
      時間差で属性を切り替えるようにしています。
    */
    this.renderCanvasElement = false;
    setTimeout(() => {
      this.renderCanvasElement = true;
    }, 10);
    setTimeout(() => {
      const canvas = document.getElementById("chart-doughnut");
      if (!canvas) return;

      new Chart(canvas as ChartItem, {
        type: "doughnut",
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "（単位：時間）"
            }
          },
          responsive: true
        }
      });
    }, 50);
  }
  mounted() {
    this.renderChart();
  }
}
</script>

<style lang="scss" scoped>
.canvas-frame {
  min-height: 95vw;
  @media screen and (min-width: 768px) {
    & {
      min-height: 0;
    }
  }
}
</style>
