
import { Learning } from "@/entities/learning";
import MIcon from "@/components/MIcon.vue";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import "dayjs/locale/ja"; //曜日を英語表記から日本語表記にするために使用

@Options({
  emits: ["load"],
  props: {
    learnings: Array,
    loadable: Boolean,
    loading: Boolean
  },
  components: {
    MIcon
  }
})
export default class MAHistoryPanel extends Vue {
  learnings: Learning[] = [];
  loadable = false;
  loading = false;

  timeTextOf(learning: Learning): string {
    const timeRecords = [...learning.data.records];
    timeRecords.sort((a, b) => a.end - b.end);
    const startTime = dayjs(timeRecords[0].start * 1000).locale("ja");
    const endTime = dayjs(
      timeRecords[timeRecords.length - 1].end * 1000
    ).locale("ja");
    return (
      startTime.format("M/D(ddd) HH:mm") + " 〜 " + endTime.format("HH:mm")
    );
  }

  hasReflectionOf(learning: Learning): boolean {
    return !!learning.data.reflection;
  }

  subjectsOf(learning: Learning): string[] {
    return learning.data?.subjects.map(subject => subject.name) ?? [];
  }

  pathOf(learning: Learning): string {
    return `/learning/${learning.ref.id}${
      learning.data.reflection ? "" : "/reflection"
    }`;
  }

  leaningTypeOf(learning: Learning): string {
    return learning.data?.meta?.type === "timer" ? "タイムキーパー" : "学習室";
  }

  load() {
    this.$emit("load");
  }
}
