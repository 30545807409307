import {
  Badge,
  badgeCollectionKey,
  HasBadge,
  hasBadgeCollectionKey
} from "@/entities/badge";
import firebase from "firebase/app";
import "firebase/firestore";

function convertToBadge(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Badge {
  return {
    ref,
    data: {
      type: data.type,
      order: data.order,
      name: data.name,
      blankImage: data.blankImage,
      image: data.image,
      thumbnail: data.thumbnail,
      description: data.description,
      placeholder: data.placeholder,
      steps: data.steps,
      stepsBadgeImage: data.stepsBadgeImage,
      stepsBadgeThumbnail: data.stepsBadgeThumbnail,
      stepsLabel: data.stepsLabel,
      stepsDescription: data.stepsDescription,
      stepsTitle: data.stepsTitle,
      stepsBadgeColors: data.stepsBadgeColors,
      onboardingButtonText: data.onboardingButtonText,
      onboardingText: data.onboardingText,
      onboardingUrl: data.onboardingUrl
    }
  };
}

function convertToHasBadge(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): HasBadge {
  return {
    ref,
    data: {
      badge: data.badge,
      stepIndex: data.stepIndex,
      transfer: data.transfer
    }
  };
}

export async function fetchBadges(): Promise<Badge[]> {
  const db = firebase.firestore();
  const snapshot = await db
    .collection(badgeCollectionKey)
    .orderBy("order", "asc")
    .get();
  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => convertToBadge(doc.data(), doc.ref));
}

export async function fetchHasBadges(
  studentRef: firebase.firestore.DocumentReference
): Promise<HasBadge[]> {
  const snapshot = await studentRef.collection(hasBadgeCollectionKey).get();
  if (snapshot.empty) {
    return [];
  }
  return snapshot.docs.map(doc => convertToHasBadge(doc.data(), doc.ref));
}
