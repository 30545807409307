<template>
  <div class="p-3 flex flex-col bg-white rounded-md">
    <p class="text-xs font-medium text-gray-500">{{ title }}</p>
    <p class="w-full text-right mt-4">
      <template v-for="value in values" :key="value.label">
        <span class="pl-1 text-xl font-bold">{{ value.num }}</span>
        <span class="pl-1 text-sm font-medium text-gray-500">
          {{ value.label }}
        </span>
      </template>
    </p>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

export type LearningSummaryData = {
  num: number;
  label: string;
};

@Options({
  props: {
    title: String,
    values: Array
  }
})
export default class MLearningSummaryCard extends Vue {
  title!: string;
  values: LearningSummaryData[] = [];
}
</script>
