
import Chart, { ChartItem } from "chart.js/auto";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    labels: Array,
    datasets: Array
  },
  watch: {
    labels: function () {
      this.renderChart();
    },
    datasets: function () {
      this.renderChart();
    }
  }
})
export default class MsBarChart extends Vue {
  labels: string[] = [];
  datasets = [];
  chart: Chart | null = null;
  renderCanvasElement = true;

  renderChart() {
    if (this.labels.length === 0 || this.datasets.length === 0) {
      return;
    }
    this.renderCanvasElement = false;
    setTimeout(() => {
      this.renderCanvasElement = true;
    }, 10);
    setTimeout(() => {
      const canvas = document.getElementById("chart-bar");
      if (!canvas) return;

      new Chart(canvas as ChartItem, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: {
          responsive: true,
          scales: {
            xAxes: {
              stacked: true
            },
            yAxes: {
              stacked: true,
              title: {
                display: true,
                text: "（単位：時間）"
              }
            }
          }
        }
      });
    }, 50);
  }
  mounted() {
    this.renderChart();
  }
}
